import Field from '@assets/scripts/components/field';
import i18n from '@assets/i18n';

// translate function of vue-i18n
const { t } = i18n.global;

/**
 * generate schema object for json forms 
 *
 * @param {String} fields
 *  fields array
 *
 * @returns {Object}
 * schema object
 */
const generateSchema = (fields) => {
    const varTypes = Field.varTypes
    let schemaObject = {
        type: 'object',
        properties: {},
        required: [],
    };
    let required = [];
    fields.forEach((field) => {
        const propertyName = field.name;
        schemaObject.properties[propertyName] = {};

        if (field.validation.regex) {
            schemaObject.properties[propertyName].pattern =
                field.validation.regex;
        }

        if (field.validation.max) {
            schemaObject.properties[propertyName].maxLength =
                field.validation.max;
        }

        if (field.type == varTypes.STRING.name || field.type == varTypes.DOCUMENTARRAY.name || field.type == varTypes.PASSWORD.name) {
            schemaObject.properties[propertyName].type = 'string';
        } else if (field.type == varTypes.BOOLEAN.name) {
            schemaObject.properties[propertyName].type = 'boolean';
        } else if (field.type == varTypes.GUID.name) {
            schemaObject.properties[propertyName].type = 'string';
            schemaObject.properties[propertyName].pattern = '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$'
        } else if (field.type == varTypes.DECIMAL.name) {
            schemaObject.properties[propertyName].type = 'string';
            schemaObject.properties[propertyName].pattern = '^(([1-9]\d{0,15})|(\d))(,[0-9]{2})?$';
            schemaObject.properties[propertyName].maxLength = 19;
        } else if (field.type == varTypes.DATE.name) {
            schemaObject.properties[propertyName].type = 'string';
            schemaObject.properties[propertyName].format = 'date';
        } else if (field.type == varTypes.DATETIME.name) {
            schemaObject.properties[propertyName].type = 'string';
            schemaObject.properties[propertyName].format = 'date-time';
        } else if (field.type == varTypes.NUMBER.name) {
            schemaObject.properties[propertyName].type = 'integer';
            schemaObject.properties[
                propertyName
            ].maximum = 2147483647;
             schemaObject.properties[
                propertyName
            ].minimum = 0;
        } else if (field.type == varTypes.LONGNUMBER.name) {
            schemaObject.properties[propertyName].type = 'integer';
            schemaObject.properties[
                propertyName
            ].maximum = 9223372036854775807;
             schemaObject.properties[
                propertyName
            ].minimum = 0;
        }
        if (field.validation.required) {
            required.push(field.name);
        }
    });
    schemaObject.required = required;
    return schemaObject;
};

const generateUiSchema = (fields, data, hasErrors, saved) => {
    let uiSchemaObject = {
        type: 'VerticalLayout',
        elements: [],
    };
    fields.forEach((field) => {
        let obj
        if(field.is_key && Object.keys(data).length !== 0 && !hasErrors && saved){
            obj = {
                type: 'Control',
                scope: `#/properties/${field.name}`,
                rule: {
                    effect: "DISABLE",
                    condition: {}
                }
            };
        }else {
            obj = {
                type: 'Control',
                scope: `#/properties/${field.name}`,
            };
        }
        if(field.type == Field.varTypes.DOCUMENTARRAY.name) {
            obj.options = {
                "multi": true
            }
        }
        if(field.type == Field.varTypes.PASSWORD.name) {
            obj.options = {"placeholder": t('jsonform.placeholder.oldPassword')}
        }
        uiSchemaObject.elements.push(obj);
    });
    return uiSchemaObject;
};

export default { 
    generateSchema,
    generateUiSchema,
}

